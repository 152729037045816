import { MotivadorAlterarPerfilInvestimentoDTO } from "./../../../../../../../fibra-shared-lib/src/lib/models/sinqia-api/motivador-alterar-perfil-investimento.dto";
import { RegistrarAlteracaoPerfilInvestimento } from "./../../../../../../../fibra-shared-lib/src/lib/models/sinqia-api/registrar-alteracao-perfil-investimento.dto";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
    DocumentoService,
    ArquivosService,
    EPerfilInvestimento,
    PerfilInvestimentoService,
    PerguntaPerfilInvestimento,
    RespostaPerfilInvestimento,
    TipoSeDocumento,
    AdesaoService,
    AdesaoCdService,
    retornaValorEnumerador,
} from "@fibra/fibra-shared-lib";
//import { PERGUNTAS } from "./questions";
import { MenuItem } from "primeng/api";
import { Router } from "@angular/router";
import { catchError, tap } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";
import { PdfViewerComponent } from "../../../../components/pdf-viewer/pdf-viewer.component";
import { ResumoAdesaoDto } from "@fibra/fibra-shared-lib/lib/models/sinqia-api";
import { AlterarPerfilInvestimentoDTO } from "@fibra/fibra-shared-lib/lib/models/sinqia-api/alterar-perfil-investimento.dto";
import { SolicitacaoAlteracaoPerfilInvestimentoDTO } from "@fibra/fibra-shared-lib/lib/models/sinqia-api/solicitar-alteracao-perfil-investimento.dto";
import * as _ from "lodash";
import { format } from "date-fns";
import { de } from "date-fns/locale";

@Component({
    selector: "app-alterar-perfil-investidor",
    templateUrl: "./alterar-perfil-investidor.component.html",
    styleUrls: ["./alterar-perfil-investidor.component.css"],
})
export class AlterarPerfilInvestidorComponent implements OnInit {
    [x: string]: any;
    //perguntas = PERGUNTAS;
    resultTotal = -1;
    resultPerfil = "";
    perfilRecomendado: EPerfilInvestimento;
    perfilEscolhido: string;
    items: MenuItem[];
    activeIndex: number = 0;
    openSection = 0;
    openSectionPerfilChanged = false;
    openToNext = false;
    ePerfilInvestimento = EPerfilInvestimento;
    perfilInvestimentoIndicado: EPerfilInvestimento;
    perguntasPerfilInvestimento: PerguntaPerfilInvestimento[] = [];
    perguntasRespondidas: PerguntaPerfilInvestimento[] = [];
    perfilInvestimento?: EPerfilInvestimento | null;
    perguntaAtual: PerguntaPerfilInvestimento;
    textoBotaoAvancar: string = "Próxima pergunta";
    exibirBotaoConcluir: boolean = false;
    desenquadramento: boolean = false;
    termoPerfilConservador: boolean = false;
    termoDesenquadramento: boolean = false;
    naoResponderQuestionario: boolean = false;
    mostrarPerguntas: boolean = false;
    desejaAlterarPerfil: boolean = false;
    mostrarPerguntaAlterarPerfil: boolean = true;
    mostrarOpcaoRefazer: boolean = false;
    mostrarOpcoesPerfil: boolean = false;
    labelBtnRefazerQuestionario: string;
    isLoading: boolean;
    adesaoContexto: ResumoAdesaoDto;
    perfilInvestimentoDTO: AlterarPerfilInvestimentoDTO;
    solicitacoes: SolicitacaoAlteracaoPerfilInvestimentoDTO[];
    ultimoPerfilInvestimento: SolicitacaoAlteracaoPerfilInvestimentoDTO;
    exibirPerguntaResponderPerfil: boolean = true;
    mostraMotivadorAlteracao: boolean = false;
    outrosMotivosChecked: boolean = false;
    motivosSelecionados: string[] = [];
    outrosMotivosTexto: string = "";
    exibirBotaoSalvarPerfil: boolean = false;
    exibirAvancarConcuirPerfil: boolean = false;
    desabilitarAvancar: boolean = true;
    mostrarBotaoAvancar: boolean = false;
    mostrarDesenquadramento: boolean = false;
    registrarAlterarPerfilInvestimento =
        {} as RegistrarAlteracaoPerfilInvestimento;
    motivos: MotivadorAlterarPerfilInvestimentoDTO[] = [];
    desabitarBotaoRegistrarAlteracao: boolean = true;

    @ViewChild("pdfViewer") pdfViewer: PdfViewerComponent;

    naoResponder: boolean = false;
    respondeu: boolean = false;
    rentabilidadePorPerfil: string[];
    mostrarModalMensagemNaoRespondeu: boolean = false;
    mostrarModalMensagemRespondeu: boolean = false;
    mostrarModalPerfilSemAlteracao: boolean = false;
    perfilSemAlteracao: boolean = false;
    mostrarModalBotaoNaoDesejo: boolean = false;

    constructor(
        private router: Router,
        @Inject(PerfilInvestimentoService)
        private perfilInvestimentoService: PerfilInvestimentoService,
        @Inject(DocumentoService) private documentoService: DocumentoService,
        @Inject(AdesaoService) private adesaoService: AdesaoService,
        @Inject(AdesaoCdService) private adesaoCDService: AdesaoCdService,
        private arquivosService: ArquivosService
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.activeIndex = 1;
        this.items = [
            { label: "Informações iniciais" },
            { label: "Perfil de investimento" },
            { label: "Conclusão" },
        ];
        this.adesaoContexto = this.adesaoService.getAdesaoContexto();
        this.motivos = MotivadorAlterarPerfilInvestimentoDTO.getMotivos();
        this.iniciarSimulador();
    }

    private iniciarSimulador() {
        this.ObterPerfilInvestimento();
        this.obterLaminasCD();
        this.termoPerfilConservador = this?.termoPerfilConservador == true;
        this.termoDesenquadramento = this?.termoDesenquadramento == true;
        this.desabitarBotaoRegistrarAlteracao = true;

        if (this?.naoResponderQuestionario == true) {
            this.ignorarQuestionario();
        } else {
            if (this.perfilInvestimento != null) {
                this.perguntasRespondidas = this.perguntasPerfilInvestimento;
                this.concluirQuestionario();
                this.perfilCheck(this.perfilInvestimento);
            }

            if (
                this.perguntasPerfilInvestimento &&
                this.perguntasPerfilInvestimento.length > 0
            ) {
                this.perguntasRespondidas = this.perguntasPerfilInvestimento;
                let perguntas = this.perguntasRespondidas
                    .filter((x) => x.respondida)
                    .sort((x, y) => y.numero - x.numero);
                this.perguntaAtual = perguntas[0];
                this.verificarRespostas(this.perguntasRespondidas);
            } else {
                this.perguntasPerfilInvestimento =
                    PerguntaPerfilInvestimento.getPerguntas();
                this.perguntasRespondidas =
                    PerguntaPerfilInvestimento.getPerguntas();
                this.perguntaAtual = this.perguntasPerfilInvestimento[0];
            }
        }
    }

    ignorarQuestionario() {
        this.ignorarPerguntasPerfilInvestimento();
        this.definirNaoResponderQuestionario(true);
        this.perfilInvestimento = EPerfilInvestimento.Conservador;
        this.resultTotal = retornaValorEnumerador(
            EPerfilInvestimento,
            this.ultimoPerfilInvestimento?.perfilInvestimento
        );
        this.naoResponderQuestionario = true;
        this.mostrarPerguntas = false;
        this.mostrarOpcaoRefazer = true;
        this.labelBtnRefazerQuestionario = "Responder questionário";

        if (this.naoResponderQuestionario) {
            this.desabilitarAvancar = false;
            this.mostrarBotaoAvancar = false;
        } else {
            this.desabilitarAvancar = false;
            this.mostrarBotaoAvancar = true;
        }

        this.mostraMotivadorAlteracao = false;
        this.desabitarBotaoRegistrarAlteracao = true;

        this.resultPerfil = null;
    }

    ignorarPerguntasPerfilInvestimento() {
        this.perguntasPerfilInvestimento = null;
        this.naoResponderQuestionario = false;
        this.perfilInvestimento = retornaValorEnumerador(
            EPerfilInvestimento,
            this.ultimoPerfilInvestimento?.perfilInvestimento
        );
        this.perfilRecomendado = retornaValorEnumerador(
            EPerfilInvestimento,
            this.ultimoPerfilInvestimento?.perfilInvestimento
        );
        this.perfilInvestimentoIndicado = retornaValorEnumerador(
            EPerfilInvestimento,
            this.ultimoPerfilInvestimento?.perfilInvestimento
        );
        this.resultTotal = retornaValorEnumerador(
            EPerfilInvestimento,
            this.ultimoPerfilInvestimento?.perfilInvestimento
        );
    }

    definirNaoResponderQuestionario(opcao: boolean) {
        this.naoResponderQuestionario = opcao;
        this.desabilitarAvancar = !opcao;
    }

    concluirQuestionario() {

        this.mostrarPerguntas = false;
        this.mostrarOpcoesPerfil = true;
        this.mostrarOpcaoRefazer = true;
        this.exibirBotaoSalvarPerfil = true;
        this.labelBtnRefazerQuestionario = "Responder novamente";
        this.resultTotal =
            this.perfilInvestimentoService.calcularResultadoQuestionario(
                this.perguntasRespondidas
            );
        this.perfilRecomendado =
            this.perfilInvestimentoService.getPerfilInvestimento(
                this.resultTotal
            );
        if (!this.perfilInvestimento) {
            this.perfilInvestimento = this.perfilRecomendado;
            this.perfilInvestimento = this.perfilRecomendado;
            this.definirPerfilInvestimento(
                this.perfilRecomendado,
                this.perfilRecomendado
            );
        }
        this.resultPerfil =
            this.perfilInvestimentoService.GerarDescricaoPerfilInvestimento(
                this.perfilRecomendado
            );

        this.perfilEscolhido =
            this.perfilInvestimentoService.GerarDescricaoPerfilInvestimento(
                this.perfilRecomendado
            );

        this.desabilitarAvancar = false;
        this.mostrarBotaoAvancar = true;
    }

    definirPerfilInvestimento(
        perfilInvestimento: EPerfilInvestimento,
        perfilInvestimentoIndicado: EPerfilInvestimento
    ) {
        this.perfilInvestimento = perfilInvestimento;
        this.perfilInvestimentoIndicado = perfilInvestimentoIndicado;
    }

    perfilCheck(perfilSelecionado: EPerfilInvestimento) {
        if (
            !Object.keys(this.ePerfilInvestimento).includes(
                perfilSelecionado.toString()
            )
        )
            return;

        this.desenquadramento =
            (this.perfilRecomendado == this.ePerfilInvestimento.Conservador &&
                perfilSelecionado == this.ePerfilInvestimento.Moderado) ||
            (this.perfilRecomendado == this.ePerfilInvestimento.Conservador &&
                perfilSelecionado == this.ePerfilInvestimento.Arrojado) ||
            (this.perfilRecomendado == this.ePerfilInvestimento.Moderado &&
                perfilSelecionado == this.ePerfilInvestimento.Arrojado);

        this.definirPerfilInvestimento(
            perfilSelecionado,
            this.perfilRecomendado
        );

        this.perfilEscolhido = this.perfilInvestimentoService.GerarDescricaoPerfilInvestimento(perfilSelecionado);

        this.perfilInvestimento = perfilSelecionado;
        this.perfilInvestimentoIndicado = this.perfilRecomendado;
        this.termoDesenquadramento = null;
        this.definirTermoDesenquadramento(false);

        this.desabilitarAvancar = this.desenquadramento;
        this.mostrarDesenquadramento = this.desenquadramento;
    }

    definirTermoDesenquadramento(aceito: boolean) {
        this.termoDesenquadramento = aceito;
        this.desabilitarAvancar = !aceito;
        this.desabitarBotaoRegistrarAlteracao = !aceito;
    }

    verificarRespostas(
        perguntasRespondidas: Array<PerguntaPerfilInvestimento>
    ) {
        let naoRespondidas = perguntasRespondidas.filter((pergunta) => {
            return pergunta.respondida == null || pergunta.respondida == false;
        });

        let numerosPerguntas = perguntasRespondidas.map((x) => x.numero);
        let numeroUltimaPergunta = Math.max(...numerosPerguntas);

        this.exibirBotaoConcluir =
            naoRespondidas.length == 0 &&
            this.perguntaAtual.numero == numeroUltimaPergunta;
    }

    avancarPergunta(perguntaAtual: PerguntaPerfilInvestimento) {
        let pergunta = this.perguntasRespondidas.find(
            (x) => x.numero == perguntaAtual.numero + 1
        );
        if (pergunta) this.perguntaAtual = pergunta;
        this.verificarRespostas(this.perguntasRespondidas);
    }

    voltarPergunta(perguntaAtual: PerguntaPerfilInvestimento) {
        let pergunta = this.perguntasRespondidas.find(
            (x) => x.numero == perguntaAtual.numero - 1
        );
        if (pergunta) this.perguntaAtual = pergunta;
        this.verificarRespostas(this.perguntasRespondidas);
    }

    marcarResposta(resposta: RespostaPerfilInvestimento) {
        this.perguntaAtual.respondida = true;
        this.perguntaAtual.respostas.forEach((x) => {
            x.selecionada = x.numero == resposta.numero;
        });
        this.atualizarPergunta(this.perguntaAtual);
    }

    atualizarPergunta(pergunta: PerguntaPerfilInvestimento) {
        let perguntas = this.perguntasRespondidas.filter(
            (x) => x.numero != pergunta.numero
        );
        perguntas.push(pergunta);
        this.perguntasRespondidas = perguntas.sort(
            (x, y) => x.numero - y.numero
        );
        this.verificarRespostas(this.perguntasRespondidas);
    }

    refazerQuestionario() {
        this.resultTotal = -1;
        this.resultPerfil = null;
        this.perfilRecomendado = null;
        this.perguntasRespondidas = [];
        this.textoBotaoAvancar = "Próxima pergunta";
        this.exibirBotaoConcluir = false;
        this.desenquadramento = false;
        this.termoPerfilConservador = false;
        this.termoDesenquadramento = false;
        this.naoResponderQuestionario = null;
        this.mostrarPerguntas = false;
        this.mostrarOpcaoRefazer = false;
        this.mostrarOpcoesPerfil = false;

        this.perguntasPerfilInvestimento = [];

        this.perfilInvestimento = null;
        this.perfilInvestimentoIndicado = null;

        this.termoDesenquadramento = false;
        this.naoResponderQuestionario = false;
        this.exibirPerguntaResponderPerfil = true;

        this.mostraMotivadorAlteracao = false;

        this.outrosMotivosChecked = false;

        this.mostrarBotaoAvancar = false;

        this.desabitarBotaoRegistrarAlteracao = true;

        this.mostrarDesenquadramento = false;

        this.motivos.forEach((motivo) => {
            motivo.selecionado = false;
            motivo.detalhes = ""; // Se quiser limpar os detalhes do motivo "Outros motivos"
        });

        this.respondeu = false;

        this.iniciarSimulador();
    }

    podeAvancar() {
        if (!this?.perfilInvestimento) {
            return false;
        }
        if (this.naoResponderQuestionario && !this?.termoPerfilConservador) {
            return false;
        }
        if (this.desenquadramento && !this?.termoDesenquadramento) {
            return false;
        }
        return true;
    }

    downloadPerfilInvestimento() {
        this.isLoading = true;

        let fileName = encodeURIComponent(
            "Cartilha Perfil de Investimentos.pdf"
        );

        let request = this.documentoService
            .getSeDocsByName(
                TipoSeDocumento.SinteseSaldamentoNovoPlanoAtivos,
                fileName
            )
            .pipe(
                tap((data) => {
                    this.isLoading = false;
                })
            )
            .pipe(
                catchError((error: any) => {
                    this.isLoading = false;
                    console.error("An error occurred:", error);
                    return throwError(error);
                })
            );

        this.pdfViewer.onSuccess.subscribe((success) => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe((error) => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request, true);
    }

    onBack() {
        this.router.navigate(["/painel/adesao-cd/informacoes-iniciais"]);
    }

    onSubmit() {
        this.router.navigate(["/painel/adesao-cd/conclusao"]);
    }

    responderPerfil(escolha: boolean, botaoNaoDesejo: boolean) {
        this.mostrarPerguntas = escolha;
        this.exibirPerguntaResponderPerfil = !escolha;
        this.naoResponder = !escolha;
        if(escolha == false && botaoNaoDesejo == false){
            this.abrirModalMensagemNaoRespondeu();
    }
        else if (escolha == false && botaoNaoDesejo == true) {
            this.abrirModalMensagemBotaoNaoDesejo();
        }

    }

    ObterPerfilInvestimento() {
        this.isLoading = true;
        this.adesaoCDService
            .ObterPerfilInvestimento()
            .subscribe((perfilInvestimento) => {
                if (perfilInvestimento.data) {
                    this.perfilInvestimentoDTO = perfilInvestimento.data;

                    if (this.perfilInvestimentoDTO.solicitacoes.length > 0) {
                        this.solicitacoes = [
                            {
                                perfilInvestimento:
                                    this.perfilInvestimentoDTO
                                        ?.descricaoPerfilAnterior,
                                dataCompetencia: format(
                                    this.adesaoContexto?.dataInscricao,
                                    "dd/MM/yyyy"
                                ),
                            },
                        ];

                        this.solicitacoes = this.solicitacoes.concat(
                            this.perfilInvestimentoDTO.solicitacoes
                        );

                        this.ultimoPerfilInvestimento = _.last(
                            this.solicitacoes
                        );
                    } else {
                        const dataCompetencia = new Date(
                            this.adesaoContexto.dataInscricao
                        );
                        this.ultimoPerfilInvestimento = {
                            perfilInvestimento:
                                this.perfilInvestimentoDTO
                                    ?.descricaoPerfilAnterior,
                            dataCompetencia: format(
                                dataCompetencia,
                                "dd/MM/yyyy"
                            ),
                        };

                        this.solicitacoes = [
                            {
                                perfilInvestimento:
                                    this.perfilInvestimentoDTO
                                        ?.descricaoPerfilAnterior,
                                dataCompetencia: format(
                                    dataCompetencia,
                                    "dd/MM/yyyy"
                                ),
                            },
                        ];
                    }
                }
                //this.isLoading = false;
            });
    }

    onOutrosMotivosChange(event: any) {
        this.outrosMotivosChecked = event.target.checked;
    }

    apresentarMotivador() {
        this.mostraMotivadorAlteracao = true;
        this.mostrarOpcoesPerfil = false;
        this.mostrarDesenquadramento = false;
        this.mostrarBotaoAvancar = false;
        this.naoResponderQuestionario = false;
        this.desabitarBotaoRegistrarAlteracao = true;
    }

    registroAlteracaoPerfilInvestimento() {


        this.isLoading = true;

        this.registrarAlterarPerfilInvestimento.idPerfilInvestimento =
            this.perfilInvestimento;
        this.registrarAlterarPerfilInvestimento.perfilInvestimentoIndicado =
            this.perfilInvestimentoIndicado;
        this.registrarAlterarPerfilInvestimento.perguntasPerfilInvestimento =
            this.perguntasRespondidas;



        let motiv = MotivadorAlterarPerfilInvestimentoDTO.toJSON(this.motivos);

        this.registrarAlterarPerfilInvestimento.motivosAlteracao = motiv;

        this.adesaoCDService
            .registrarAlterarPerfilInvestimento(
                this.registrarAlterarPerfilInvestimento
            )
            .subscribe(
                (response) => {
                    if (response) {

                        //this.downloaRegistroPerfilInestimento();
                        this.desabitarBotaoRegistrarAlteracao = true;
                        this.respondeu = true;
                        this.naoResponderQuestionario = false;
                        this.mostrarOpcaoRefazer = false;
                        this.mostraMotivadorAlteracao = false;
                        this.abrirModalMensagemRespondeu();
                    } else {
                        console.log(
                            "Falha: Não foi possível registrar a alteração."
                        );
                    }
                },
                (error) => {
                    console.error("Erro:", error);
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    verificarCheckboxSelecionado() {
        this.desabitarBotaoRegistrarAlteracao = !this.motivos.some(
            (motivo) => motivo.selecionado
        );
    }

    downloaRegistroPerfilInestimento() {
        this.isLoading = true;

        this.adesaoCDService.downloaRegistroPerfilInestimento().subscribe(
            (blob) => {
                console.log(blob);
                const url = window.URL.createObjectURL(blob);
                window.open(url);
                window.URL.revokeObjectURL(url);
            },
            (error) => {
                console.log("Erro ao baixar o arquivo", error);
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    abrirSEDocumento() {
        this.isLoading = true;
        let request = this.documentoService
            .getSeDocs(TipoSeDocumento.PoliticaIvestimentoPCD)
            .pipe(
                tap((data) => {
                    this.isLoading = false;
                })
            )
            .pipe(
                catchError((error: any) => {
                    this.isLoading = false;
                    console.error("An error occurred:", error);
                    return throwError(error);
                })
            );

        this.pdfViewer.onSuccess.subscribe((success) => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe((error) => {
            console.error(error);
            this.isLoading = false;
        });

        this.pdfViewer.getPdfData(request, true);
    }

    downloadFile(idDoc: string, nomeDoc: string) {
        this.isLoading = true;

        let nomeDocEncode = encodeURIComponent(nomeDoc);

        // Passa o Observable diretamente para o pdfViewer, sem subscrever manualmente
        const request = this.documentoService.getSeDocsById(
            idDoc,
            nomeDocEncode
        );

        this.pdfViewer.getPdfData(request, true);

        this.pdfViewer.onSuccess.subscribe((success) => {
            this.isLoading = false;
        });

        this.pdfViewer.onError.subscribe((error) => {
            console.error(error);
            this.isLoading = false;
        });
    }

    /*obterLaminasCD() {

        this.isLoading = true;
        this.arquivosService.getListSeDocs(TipoSeDocumento.LaminaCD).subscribe(
            (files) => {
                this.rentabilidadePorPerfil = files;
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
                console.log("Erro ao obter lâminas de investimento", error);
            },
            () => {
                this.isLoading = false;
            }
        );
    }*/

    obterLaminasCD() {
        this.isLoading = true;
        this.arquivosService
            .getListSeDocs(TipoSeDocumento.LaminaCD)
            .subscribe(
                (files) => {
                    this.rentabilidadePorPerfil = files;
                },
                (error) => {
                    console.log("Erro ao obter lâminas de investimento", error);
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    abrirModalMensagemNaoRespondeu(): void {
        this.mostrarModalMensagemNaoRespondeu = true;
      }

      abrirModalMensagemBotaoNaoDesejo(): void {
        this.mostrarModalBotaoNaoDesejo = true;
      }


      // Método para fechar a modal
      fecharModalMensagemNaoRespondeu(): void {
        this.mostrarModalMensagemNaoRespondeu = false;
        this.router.navigate(['/painel/plano-cd/inicio']);
      }

      abrirModalMensagemRespondeu(): void {
        this.mostrarModalMensagemRespondeu = true;
      }

      // Método para fechar a modal
      fecharModalMensagemRespondeu(): void {
        this.mostrarModalMensagemRespondeu = false;
        this.router.navigate(['/painel/plano-cd/inicio']);
      }

      fecharModalBotaoNaoDesejo(): void {
        this.mostrarModalBotaoNaoDesejo = false;
        this.router.navigate(['/painel/plano-cd/inicio']);
      }

      FechaModalRegistraPerfilSemAlteracao(): void {
        this.mostrarModalPerfilSemAlteracao = false;

        setTimeout(() => {
          this.registroAlteracaoPerfilInvestimento();
        }, 1000); // 1000 milissegundos = 1 segundo
      }

      resetarPagina(): void {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([this.router.url]);
        });
      }

      ValidaProcessamento()
      {
        if(this.perfilInvestimento === this.perfilInvestimentoIndicado)
        {
            this.perfilSemAlteracao = true;
            this.mostrarModalPerfilSemAlteracao = true
        }
        else{
            this.registroAlteracaoPerfilInvestimento();
        }
      }
}
