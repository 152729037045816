import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdesaoService, DadosCadastraisService, ESituacaoGeral, ETipoPlano, TipoSituacaoParticipante, UploadClientService } from '@fibra/fibra-shared-lib';
import { DadosCadastraisSinqiaDto, ResumoAdesaoDto } from '@fibra/fibra-shared-lib/lib/models/sinqia-api';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-troca-plano',
    templateUrl: './troca-plano.component.html',
    styleUrls: ['./troca-plano.component.css']
})
export class TrocaPlanoComponent implements OnInit {


    planos: Array<ETipoPlano> = [];
    isLoading: boolean = false;
    eTipoPlano = ETipoPlano;
    mostrarMenuAdesao: boolean = false;
    mostrarDialogAdesao = false;
    adesoesPlano: Array<ResumoAdesaoDto> = [];
    mostrarAlertaAdesaoPendent: boolean = false;
    mostrarBannerAdePendente: boolean = false;
    eSituacaoGeral = ESituacaoGeral;
    adesaoIndisponivel: boolean;
    paginaCarregada: boolean = false;

    constructor(private dadosCadService: DadosCadastraisService,
        private router: Router,
        private adesaoService: AdesaoService) { }

    ngOnInit() {
        this.isLoading = true;
        this.dadosCadService.getCadastroGeral(false)
            .subscribe((dadosCad: DadosCadastraisSinqiaDto) => {
                // O plano CD só poderá ser acessado pelos participantes ativos.
                // Ou seja, para assistidos não deverá apresentar a opção de adesão e nem a opção do plano CD.
                this.planos = [];
                let situacoes: Array<string> = [];
                let situacoesParaNaoCadastrar = ["bpd", "vesting", "autopatrocionado", "autopatrocionado requisitado"]

                dadosCad.adesoes.forEach(ade => {
                    if(sessionStorage.getItem('funcionarioNovoExBETM') != "true"){
                        this.planos.push(ade.idPlano);
                    }else if(ade.idPlano != 1){
                        this.planos.push(ade.idPlano);
                    }

                    if (situacoesParaNaoCadastrar.indexOf(ade.situacaoParticipante.toLocaleLowerCase()) < 0)
                        situacoes.push(ade.tipoSituacao);
                });

                this.mostrarBannerAdePendente
                    = dadosCad.informacoesAdicionais.situacaoGeral == ESituacaoGeral.AdesaoCdPendente;

                // Caso a situação do participante seja 'Ativo' e que não possua o plano 'CD' deverá apresentar a opção de adesão do plano CD
                this.mostrarMenuAdesao = ((
                    situacoes.includes(TipoSituacaoParticipante.ATIVO)
                ) && !this.planos.includes(this.eTipoPlano.PlanoCD)) ||
                    dadosCad.adesoes.length == 0;

                if(sessionStorage.getItem('funcionarioNovoExBETM') == "true"){
                    this.mostrarMenuAdesao = true;
                }

                this.isLoading = false;
                this.paginaCarregada = true;
            }, error => {
                this.isLoading = false;
                console.error(error);
            });
    }

    escolherPlano(plano: ETipoPlano) {
        const adesoes = this.adesaoService.listarAdesoesPorPlano(plano);
        if (adesoes.length > 1) {
            this.mostrarDialogAdesao = true;
            this.adesoesPlano = [];
            adesoes.forEach(ade => {
                this.adesoesPlano.push(ade);
            });
            return;
        } else if (adesoes.length > 0) {
            this.selecionarAdesao(adesoes[0]);
        }
    }

    selecionarAdesao(adesao: ResumoAdesaoDto) {
        if (!adesao) {
            console.error('Adesão não definida!');
            return;
        }

        this.adesaoService.definirAdesaoContexto(adesao);

        switch (adesao.idPlano as ETipoPlano) {
            case ETipoPlano.PlanoBD:
                this.router.navigate(['/painel/plano-bd']);
                break;
            case ETipoPlano.PlanoCD:
                this.router.navigate(['/painel/plano-cd']);
                break;
            default:
                break;
        }
    }

    aderirPlanoCd() {
        this.adesaoService.resetarAdesaoContextoPorPlano();
        this.router.navigate(['/painel/adesao-cd']);
    }

    mostrarAlertaAdePendente() {
        this.mostrarAlertaAdesaoPendent = true;
        this.adesaoService.resetarAdesaoContextoPorPlano();
    }

    fecharAlertaAdesaoPendente() {
        this.mostrarAlertaAdesaoPendent = false;
    }

    irParaPaginaAlteracaoPerfilInvestimento() {
        this.escolherPlano(ETipoPlano.PlanoCD);
        this.router.navigate(['/painel/plano-cd/alterar-perfil-investidor']);
    }

    habilitaAlteracaoPerfilInvestimento(): boolean {
        /*const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        let startDate = new Date();
        let endDate = new Date();

        if(environment.production)
        {
            startDate = new Date(currentYear, 1, 6);
            endDate = new Date(currentYear, 2, 16);
        }
        else{
            startDate = new Date(currentYear, 0, 1);
            endDate = new Date(currentYear, 12, 15);
        }*/

        return true;//currentDate >= startDate && currentDate <= endDate;
      }

}
