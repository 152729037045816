import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AlterarPerfilInvestimentoGuard implements CanActivate {

  constructor(private router: Router) {}

  // Método que verifica se a data está no intervalo desejado
  private isDateBetween(): boolean {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    let startDate = new Date();
    let endDate = new Date();

    /*if(environment.production)
        {
            startDate = new Date(currentYear, 1, 6);
            endDate = new Date(currentYear, 2, 16);
        }
        else{
            startDate = new Date(currentYear, 1, 1);
            endDate = new Date(currentYear, 12, 31);
        }
*/
    return true; //currentDate >= startDate && currentDate <= endDate;
  }

  canActivate(): boolean {
    if (this.isDateBetween()) {
      return true;
    } else {
      // Redireciona para outra página caso a data não esteja no intervalo
      this.router.navigate(['/painel']); // Substitua com sua rota de acesso negado
      return false;
    }
  }
}

